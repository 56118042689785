@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

:root {
    --text-color: #333333;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: var(--text-color);
}

a {
    color: inherit;
    text-decoration: none;
}

.container {
    display: grid;
    grid-template-columns: 40% 60%;
    width: 100vw;
    height: 100vh;
}

.container > .left {
    background: url(../img/logo.png), url(../img/background.jpg);
    background-size: 12rem, cover;
    background-position: center, 0;
    background-repeat: no-repeat;
    color: white;
    position: relative;
}

.container > .left > .active {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
}

.container > .right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container > .right > .content {
    max-width: 480px;
    padding: 1rem;
}

.container > .right > .content > h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.container > .right > .content > h2 {
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
}

.container > .right > .content > p {
    line-height: 1.5rem;
}

.container > .right > .content > p > a {
    position: relative;
}

.container > .right > .content > p > a:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.2rem;
    width: 0;
    height: 1px;
    background-color: var(--text-color);
    transition: width 0.3s ease-out;
}

.container > .right > .content > p > a:hover:after {
    width: 100%;
}

@media (max-width: 768px) {
    .container {
        display: block;
        height: auto;
    }
    
    .container > .left {
        width: 100vw;
        height: 100vw;
    }
    
    .container > .right {
        margin: 2rem 0;
    }

    .container > .right > .content > p > a:after {
        width: 100%;
        transition: none;
    }
}
